import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AdminProfile} from "../models";
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/compat/firestore";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private sub: any;
    profileSub: Subscription;
    currentUser: any;
    private readonly authState: Observable<any>;
    profileDoc: AngularFirestoreDocument<AdminProfile>;

    userProfile = {} as AdminProfile;
    uid: string;
    isLoggedIn: boolean = false;


    constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
        this.authState = this.afAuth.authState;

    }

    checkLogin() {
        return new Promise((resolve, reject) => {
            this.sub = this.authState.subscribe(user => {
                if (user) {
                    this.isLoggedIn = true;
                    this.currentUser = user;
                    this.uid = user.uid;
                    console.log('auth is logged in as ', user.uid);
                    this.profileDoc = this.afs.collection('staffs').doc(user.uid);
                    this.loadProfile();
                    resolve(true);
                } else {
                    console.log('No user!');
                    resolve(false);
                    this.isLoggedIn = false;
                }
            }, err => {
                this.isLoggedIn = false;
                reject(err);
            });
        });
    }

    loadProfile() {
        return new Promise((resolve, reject) => {
            this.profileSub = this.profileDoc.valueChanges().subscribe((data) => {
                console.log('profile info ', data);
                if (data) {
                    this.userProfile = data;
                    // get jwt
                    resolve(this.userProfile);
                }
            }, error1 => {
                console.log('getting profile error ', error1);
                reject(error1);
            });
        });
    }

    emailLogin(cred) {
        return this.afAuth.signInWithEmailAndPassword(cred.email, cred.password)
        //signInWithEmailAndPassword(cred.email, cred.password);
    }

    async signOut() {
        this.currentUser = null;
        this.isLoggedIn = false;
        if (this.profileSub) {
            this.profileSub.unsubscribe();
        }

        await localStorage.setItem('jwt', null);
        return this.afAuth.signOut();
    }

    passwordReset(email) {
        /*return new Promise((resolve, reject) => {
          firebase.auth().sendPasswordResetEmail(email).then(() => {
            resolve({success: true});
          }).catch((err) => {
            reject(err);
          });
        });*/
    }

    updatePassword(oldPass, newPassword) {
        return new Promise((resolve, reject) => {
            const cred = {
                email: this.currentUser.email,
                password: oldPass
            };
            this.emailLogin(cred).then((res) => {
                res.user.updatePassword(newPassword).then(() => {
                    resolve({done: true});
                }, (err) => {
                    reject(err);
                });
            }, err => {
                reject(err);
            });
        });
    }

    makeId() {
        return this.afs.createId();
    }

}
