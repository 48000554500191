import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
	providedIn: 'root'
})
export class NoteService {

	constructor(private toastr: ToastrService) {
	}

	showSuccess(title, msg, timeout: number = 5000) {
		this.toastr.success(msg, title, {enableHtml: true, progressBar: true, timeOut: timeout});
	}

	showWarning(title, msg, timeout: number = 5000) {
		this.toastr.warning(msg, title, {enableHtml: true, progressBar: true, timeOut: timeout});
	}

	showInfo(title, msg, timeout: number = 5000) {
		this.toastr.info(msg, title, {enableHtml: true, progressBar: true, timeOut: timeout});
	}

	showError(title, msg, timeout: number = 5000) {
		this.toastr.error(msg, title, {enableHtml: true, progressBar: true, timeOut: timeout});
	}

	swalSuccess(message) {
		Swal.fire(
			'Done!',
			message,
			'success'
		);
	}

	swalError(message) {
		Swal.fire(
			'Error',
			message,
			'error'
		);
	}

	connError(msg = '') {
		if (msg === '') {
			msg = 'Unable to complete request. Please check internet connection.';
		}
		this.showError('Connection error', msg);
	}

	confirmBox() {
		Swal.fire({
			title: 'Are you sure want to remove?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it'
		}).then((result) => {
			if (result.value) {
				Swal.fire(
					'Deleted!',
					'Your imaginary file has been deleted.',
					'success'
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Your imaginary file is safe :)',
					'error'
				);
			}
		});
	}
}
