import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ContentComponent} from "./shared/components/layout/content/content.component";
import {FullComponent} from "./shared/components/layout/full/full.component";
import {full} from "./shared/routes/full.routes";
import {content} from "./shared/routes/routes";
import {LoginComponent} from "./auth/login/login.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        component: ContentComponent,
        children: content
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '**',
        redirectTo: ''
    }
];


@NgModule({
    imports: [
        [
            RouterModule.forRoot(routes, {
                anchorScrolling: "enabled",
                scrollPositionRestoration: "enabled",
            }),
        ],
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
