import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConnectivityService} from "../../shared/services/conn/connectivity.service";
import {AuthService} from "../../shared/services/auth/auth.service";
import {NoteService} from "../../shared/services/note/note.service";
import {StorageService} from "../../shared/services/storage/storage.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    public newUser = false;
    // public user: firebase.User;
    public loginForm: FormGroup;
    public show: boolean = false
    public errorMessage: any;

    constructor(private router: Router, private fb: FormBuilder, private titleService: Title, private alertService: NoteService,
                private conn: ConnectivityService, private auth: AuthService, private storageService: StorageService) {
        this.titleService.setTitle('Login | Dukes Admin')
        this.loginForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
        });
    }

    ngOnInit() {
    }

  /*  login() {
        if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
            let user = {
                email: "Test@gmail.com",
                password: "test123",
                name: "test user",
            };
            localStorage.setItem("user", JSON.stringify(user));
            this.router.navigate(["/dashboard/default"]);
        }
    }*/

    async login() {
        const email = this.loginForm.value["email"];
        const password = this.loginForm.value["password"];
        if (email === '' || password === '') {
            this.alertService.showWarning('Invalid details', 'Please enter email and password.');
        } else {
            const cred = {email: email, password: password}
            this.auth.emailLogin(cred).then(async () => {
                console.log('Logged in');
                this.auth.isLoggedIn = true;
                await this.router.navigate(['/']);
            }, (error: { message: any; }) => {
                this.alertService.showError('Error', error.message || 'Login failed, try again');
            });
        }
    }

    showPassword() {
        this.show = !this.show
    }
}
