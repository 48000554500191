import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';

@Injectable({
	providedIn: 'root'
})
export class ConnectivityService {
	endpoint = 'https://yescapital.ng/api';

	constructor(private http: HttpClient, private storage: StorageService,
				) {
	}

	sendGet(path) {
		return this.http.get<any>(this.endpoint + path);
	}

	sendPost(route, data) {
		return this.http.post<any>(this.endpoint + route, JSON.stringify(data));
	}
}
