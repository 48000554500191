import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// // for HttpClient import:
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
// // for Router import:
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
// // for Core import:
import {LoadingBarModule} from '@ngx-loading-bar/core';

import {CookieService} from 'ngx-cookie-service';

import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';

import {OverlayModule} from '@angular/cdk/overlay';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from "../environments/environment";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {ToastrModule} from "ngx-toastr";
import {DpDatePickerModule} from "ng2-date-picker";

import * as $ from "jquery"

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        OverlayModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        DpDatePickerModule,
        ToastrModule.forRoot(),
//     // for HttpClient use:
        LoadingBarHttpClientModule,
//     // for Router use:
        LoadingBarRouterModule,
//     // for Core use:
        LoadingBarModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule, AngularFireStorageModule, AngularFirestoreModule,
    ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
