<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyright {{ today | date:'y'}} © <a href="https://cloudifymedia.com" target="_blank">cloudifymedia.com</a></p>
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0">Developed with <i class="fa fa-heart font-secondary"></i></p>
    </div>
  </div>
</div>
