import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
    public userName: string;
    public profileImg: "assets/images/dashboard/profile.jpg";

    constructor(public router: Router, public auth: AuthService) {

    }

    ngOnInit() {
    }

    logoutFunc() {
        this.router.navigateByUrl('auth/login');
    }

    async logout(): Promise<void> {
        this.auth.signOut();
        console.log('OUTACHAIR')
        await this.router.navigate(['/login']);
    }
}
