import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor() {
	}

	public get(key: string) {
		return new Promise(resolve => {
			resolve(localStorage.getItem(key));
		});
	}

	public set(key: string, data: any) {
		return new Promise<void>(resolve => {
			localStorage.setItem(key, data);
			resolve();
		});
	}
}
