import {Component, OnInit, Inject} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {NavService} from "../../services/nav.service";
import {LayoutService} from "../../services/layout.service";
import SwiperCore, {Navigation, Pagination, Autoplay} from "swiper";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export interface Menu {
    headTitle1?: string,
    headTitle2?: string,
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    public elem: any;
    public search: boolean = false;
    public searchResult: boolean = false;
    public searchResultEmpty: boolean = false;
    public text: string;
    public items: Menu[];
    public menuItems: Menu[];

    constructor(public layout: LayoutService, public navServices: NavService, @Inject(DOCUMENT) private document: any) {
    }

    ngOnInit() {
        this.elem = document.documentElement;
        // this.navServices.items.subscribe(menuItems => this.items = menuItems);
        // this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
        this.navServices.megaMenu = false;
        this.navServices.levelMenu = false;
    }

    layoutToggle() {
        if ((this.layout.config.settings.layout_version = "dark-only")) {
            document.body.classList.toggle("dark-only");
        }
        document.body.remove;
    }

    searchToggle() {
        this.navServices.search = true;
    }

    languageToggle() {
        this.navServices.language = !this.navServices.language;
    }

    toggleFullScreen() {
        this.navServices.fullScreen = !this.navServices.fullScreen;
        if (this.navServices.fullScreen) {
            if (this.elem.requestFullscreen) {
                this.elem.requestFullscreen();
            } else if (this.elem.mozRequestFullScreen) {
                /* Firefox */
                this.elem.mozRequestFullScreen();
            } else if (this.elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.elem.webkitRequestFullscreen();
            } else if (this.elem.msRequestFullscreen) {
                /* IE/Edge */
                this.elem.msRequestFullscreen();
            }
        } else {
            if (!this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    searchTerm(term: any) {
        term ? this.addFix() : this.removeFix();
        if (!term) {
            return this.menuItems = [];
        }
        const items = [];
        term = term.toLowerCase();
        this.items.filter(menuItems => {
            if (!menuItems?.title) {
                return false;
            }
            if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
                items.push(menuItems);
            }
            if (!menuItems.children) {
                return false;
            }
            menuItems.children.filter(subItems => {
                if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
                    subItems.icon = menuItems.icon;
                    items.push(subItems);
                }
                if (!subItems.children) {
                    return false;
                }
                subItems.children.filter(suSubItems => {
                    if (suSubItems.title.toLowerCase().includes(term)) {
                        suSubItems.icon = menuItems.icon;
                        items.push(suSubItems);
                    }
                });
            });
            this.checkSearchResultEmpty(items);
            this.menuItems = items;
        });
    }

    checkSearchResultEmpty(items) {
        if (!items.length) {
            this.searchResultEmpty = true;
        } else {
            this.searchResultEmpty = false;
        }
    }

    addFix() {
        this.searchResult = true;
        document.getElementsByTagName('body')[0].classList.add('offcanvas');
    }

    removeFix() {
        this.searchResult = false;
        this.text = '';
        document.getElementsByTagName('body')[0].classList.remove('offcanvas');
    }
}
