<div class="media profile-media">
  <img class="b-r-10" style="height: 28px" src="{{auth.userProfile.dp || 'assets/images/svg/user.svg'}}" alt="">
  <div class="media-body"><span>{{auth.userProfile.firstName}} {{auth.userProfile.lastName}}</span>
    <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="https://mail.zoho.com" target="_blank"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>
  <li (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>
